import * as React from "react"
import { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ResourcesIntroduction from "../components/resourcesIntroduction"
import ResourcesFilters from "../components/resourcesFilters"
import ResourceCard from "../components/elements/resourceCard"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-website-light.jpg"
import Favicon from "../images/favicon.png"

const Blogs = ({ data, ...props }) => {
  const blogs = data.allStrapiBlogs.nodes

  const [list, setList] = useState([...blogs.slice(0, 9)])
  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)
  // State to check whether there is more to load
  const [hasMore, setHasMore] = useState(blogs.length > 9)
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }
  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < blogs.length
      const nextResults = isMore
        ? blogs.slice(currentLength, currentLength + 9)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < blogs.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <Layout>
      <div className="page resources">
        <Seo
          title="Loyall – Wij zijn een digital lifecycle agency"
          image={{ src: MetaImage, height: 628, width: 1200 }}
          description="We hebben de complete digitale mix in huis om jouw bedrijf duurzaam te laten groeien. Van marketing tot development, van design tot integraties. Met de digitale lifecycle investeren we in krachtige relaties met je doelgroep."
          pathname={props.location.pathname}
          favicon={Favicon}
        />
        <ResourcesIntroduction
          title="All you need for a head start"
          subtitle="Resources"
          padding={{ bottom: 32, top: 80, left: 15, right: 15 }}
        />
        <ResourcesFilters />
        <div className="component container resources_overview">
          <Row>
            {/* uncomment when required by marketing */}
            {/* <Col md={4} className="resources_categories">
              <h4>Categorieen</h4>
              <div className="categories_wrapper">
                {blogs.map((category, i) => (
                  <Link className="category">{category.name}</Link>
                ))}
              </div>
            </Col> */}
            <Col md={12} className="resources_wrapper">
              {list.map((blog, i) => (
                <ResourceCard
                  name="Blogs"
                  resource={blog}
                  id={`blog_${blog.strapiId}`}
                  key={i}
                />
              ))}
            </Col>
          </Row>
          <div className="text-center pt-4">
            {hasMore ? (
              <button
                onClick={handleLoadMore}
                className="btn btn-solid btn-loyall"
              >
                Meer laden
              </button>
            ) : (
              <p></p>
            )}
          </div>
        </div>
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
          titleSize="h2"
          className="resources-cta"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllBlogsQuery {
    allStrapiBlogs(sort: { fields: published_at, order: DESC }) {
      nodes {
        id
        strapiId
        title
        slug
        time_to_read
        published_at(formatString: "DD MMMM YYYY", locale: "nl")
        updated_at(formatString: "DD MMMM YYYY", locale: "nl")
        labels {
          id
          name
          slug
        }
        blog_categories {
          id
          name
          slug
        }
        featured_image {
          alternativeText
          url
          localFile {
            absolutePath
            publicURL
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.5)
            }
          }
          formats {
            small {
              height
              name
              size
              url
              width
            }
          }
        }
      }
    }
  }
`

export default Blogs
